html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.navbar .dropdown-menu ul.menu li {
  border-bottom: none !important;
}

.content {
  line-height: 1.5;
  flex-grow: 1;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.min-height-table {
  .dx-datagrid-rowsview {
    min-height: 290px;
  }
  .dx-datagrid-borders
    > .dx-datagrid-rowsview.dx-last-row-border
    tbody:last-child
    > .dx-data-row:nth-last-child(2)
    > td {
    border-bottom: 1px solid #dfdfdf;
  }
}
.helper {
  margin: auto !important;
  display: inline-block;
  height: 40%;
  vertical-align: middle;
}
.content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.dx-header-row {
  td {
    text-align: center !important;
  }
}
// $side-panel-min-width: 65px;

.dx-selectbox .dx-texteditor-input,
.dx-dropdowneditor-input-wrapper .dx-texteditor-input {
  padding: 10px !important;
}

.addContainer.vertical-height {
  height: 85vh;
  .mat-dialog-content {
    max-height: 80vh;
    overflow: hidden;
    .min-height-table .dx-datagrid-rowsview {
      min-height: 32vh;
      overflow-y: auto;
    }
  }
}
.table-height {
  height: 75vh !important;
}
.small-tag {
  overflow-y: inherit;
  z-index: 100;
  .dx-dropdowneditor.dx-dropdowneditor-field-clickable {
    height: 103px;
    .dx-texteditor-container{
      overflow-y: scroll;
    }
  }
  .dx-tag {
    margin: 6px 4px 0 0;
    .dx-tag-content {
      padding: 4px 28px 4px 4px !important;
      span {
        font-size: 11px !important;
      }
    }
  }
}

.tab-hd{
  max-height: 495px !important;
}

.op-tab-hg{
  .dx-datagrid-rowsview.dx-empty,.dx-scrollable{
    height: 302px;
  }
}
.small-tags{
  overflow-y: inherit;
  z-index: 100;
  .dx-dropdowneditor.dx-dropdowneditor-field-clickable {
    height: 40px;
}
.dx-texteditor-container{
  overflow-y: scroll;
}

.dx-tag {
  margin: 6px 4px 0 0;
  .dx-tag-content {
    padding: 4px 28px 4px 4px !important;
    span {
      font-size: 11px !important;
    }
  }
}
}

//  .mini-tab-hgs .dx-datagrid-rowsview{
//   height: 25vh !important;
//   min-height: 18vh !important;
// }
.rept-tab .dx-datagrid-rowsview {
min-height: 0px !important;
}
.dx-pager{
  position: sticky;
  bottom: 0;
  background: white;
  // margin-top: 20px;
}
.dx-datagrid .dx-datagrid-headers{
  position: sticky;
  top: 0;
  // z-index: 999;
}
.popup-body-con{
  overflow-y: hidden !important;
}
.dx-pager{
  z-index: 999;
  // margin-top: 20px;
  // margin-bottom: 40px;
}
  // .mini-tab-hgs .dx-datagrid-rowsview {

  //   height: 43vh !important;
  //   min-height: 30vh !important;
  // }

.bck-color .dx-texteditor.dx-editor-outlined.dx-state-disabled .dx-texteditor-input {
  color: rgb(0, 0, 0)!important;
  font-weight: 400 !important;
}


.bck-color .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-disabled .dx-texteditor-label .dx-label{
  color: rgb(0, 0, 0)!important;
  font-weight: 400;
}

.role-mapp-drp-dwn
{
  .dropdown-list ul.item2
  {
    height: 100px !important;
  }
  
}
.multi-tab{
  .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable  {
    // height: 17vh !important;
    // min-height: 26vh !important;

    height: 30vh !important;
    min-height: 35vh !important;
  }
}
.multi-tab-1{
  .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
    height: 45vh !important;
  }
}
// .mini-tab-hgs .dx-datagrid-rowsview{
//   height: 28vh;
//   min-height: 28vh;
// }
 .cdk-overlay-pane
 {

 .mat-dialog-container{
 padding: 0px 10px 10px 10px !important;
 }
}
.modalHeader{
  padding: 10px !important;
}
.tab-scroll{
  .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
    top: 0;
    position: sticky;
    overflow-y: auto;
    height: 40vh;
}
}
.tab-scroll{
 .dx-datagrid-headers.dx-datagrid-nowrap{
 z-index: 999 !important;
  }
}
.part-his-scrll{
  overflow-y: hidden !important;
}
// .part-his-scrll.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
//   top: 0;
//     position: sticky;
//     overflow-y: auto;
//     height: 35vh !important;
// }
// .mlk-grid-hg{
//   height: 40vh !important;
//   max-height: 40vh !important;
// }
.ck-editor-hg{
  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
    height: 175px !important;
  }
}
.inp-scrll{
  .mat-chip-list-wrapper{
    height: 30px !important;
    overflow-y: auto !important;
  }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{
    font-size: 11px;
    border-radius: 0px !important;
    padding: 5px;
    height: 4px;
  }
}
.fl-upload{
  .file-drop-area{
    width: 50% !important;
    padding: 20px !important;
  }
}
.imp-height{
  min-height: 364px !important;
  max-height: 50vh !important;
}
.imp-tab-head-fix{
  .dx-datagrid .dx-datagrid-headers{
    z-index: 99 !important;
  }
}
.rtm-frm-hg{
  margin-top: -30px !important;
  height: 55vh !important;
  min-height: 55vh !important;
}
.part-view{
  .dx-datagrid-headers + .dx-datagrid-rowsview{
  height: 30vh !important;
}
.dx-datagrid-rowsview .dx-row{
  border-bottom: 1px solid #ebe2e2 !important;
}
}
.mlk-coll-tab{
  .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
    height: 45vh !important;
  }
}
.brn-ovrfl{
  overflow-y: hidden !important;
  .dx-datagrid .dx-datagrid-headers{
    z-index: 111 !important;
  }
  .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
    overflow-y: auto !important;
    height: 37vh !important;
  }
}
.add-msp-vndr{
  .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
    height: 20vh !important;
  }
}
.cus-br-inp{
  .multiselect-dropdown .dropdown-btn{
    min-height: 38px !important;
  }
}