/*
 *  Document   : style.css
 *  Author     : RedStar Template
 *  Description: This is a main style scss file for import all scss files.
 *
 *  Structure (with shortcodes):
                 [1. Common ]
                 [2. Fonts ]                   
                 [3. Components ]       
                 [4. Apps]                   
                 [5. Pages ]    
                 [6. Pugins ]     
                 [7. UI ]     
                 [8. Browser ]                               



/*                 [1. Common ]                   			*/
@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "common/_variables";
@import "common/_mixins";
@import "common/_customanimate.scss";
@import "common/_general.scss";
@import "common/_demo.scss";
@import "common/_helpers.scss";
@import "common/_media.scss";
@import "common/_animation.scss";
@import "common/_rtl.scss";
/*                 [2. Icons ]                   				*/
// @import "fonts/_material.scss";
@import "fonts/fontawesome/fontawesome.scss";
@import "fonts/fontawesome/regular.scss";
@import "fonts/fontawesome/solid.scss";
@import "fonts/fontawesome/brands.scss";
@import url(../fonts/themify-icons/themify-icons.css);
@import url(../fonts/simple-line-icons/css/simple-line-icons.css);

/*                 [3. Components ]                   		*/
@import "components/_breadcrumbs.scss";
@import "components/_checkboxradio.scss";
@import "components/_dropdownmenu.scss";
@import "components/_feed.scss";
@import "components/_formcomponents.scss";
@import "components/_infobox.scss";
@import "components/_inputformgroup.scss";
@import "components/_labels.scss";
@import "components/_leftsidebaroverlay.scss";
@import "components/_navbar";
@import "components/_navtabs.scss";
@import "components/_noticeboard.scss";
@import "components/_rightsidebar.scss";
@import "components/_searchbar.scss";
@import "components/_settingSidebar.scss";
@import "components/_switch.scss";
@import "components/_thumbnails.scss";
@import "components/_todo.scss";

/*                 [4. Apps]                   				*/
@import "apps/_calendar.scss";
@import "apps/_chat.scss";
@import "apps/_contactlist.scss";
@import "apps/_contactgrid.scss";
@import "apps/_dragdrop.scss";
@import "apps/_task.scss";

/*                 [5. Pages ]                   				*/
@import "pages/_dashboard.scss";
@import "pages/_inbox.scss";
@import "pages/_pricing.scss";
@import "pages/_profile.scss";
@import "pages/_timeline.scss";
@import "pages/_extra_pages.scss";
@import "pages/_auth.scss";

/*                 [6. Pugins ]                   				*/
@import "plugins/_carousel.scss";
@import "plugins/_charts.scss";
@import "plugins/_formwizard.scss";
@import "plugins/_imagegallery.scss";
@import "plugins/_maps.scss";
@import "plugins/_tables.scss";

/*                 [7. UI ]                   */
@import "ui/_alerts.scss";
@import "ui/_badgelistgroupitem.scss";
@import "ui/_buttons.scss";
@import "ui/_card.scss";
@import "ui/_collapse.scss";
@import "ui/_dialogs.scss";
@import "ui/_expansion.scss";
@import "ui/_mediaobject.scss";
@import "ui/_modals.scss";
@import "ui/_pageloader.scss";
@import "ui/_pagination.scss";
@import "ui/_panels.scss";
@import "ui/_preloaders.scss";
@import "ui/_progressbars.scss";
@import "ui/_slider.scss";
@import "ui/_snackbar.scss";
@import "ui/_tabs.scss";
@import "ui/_tooltippopovers.scss";
@import "ui/_ckeditor.scss";

/*                 [8. Browser ]                   			*/
@import "browser/_ie10.scss";
@import "browser/_ie11.scss";

body {
  .navbar-brand {
    img {
      vertical-align: middle;
    }
    .logo-name {
      font-size: 20px;
      color: #707277 !important;
      font-weight: bold;
      vertical-align: middle;
    }
  }
  overflow: hidden;
  .main-dashboard {
    max-height: 600px;
    overflow-y: scroll;
  }
  .mat-row:nth-child(even) {
    background-color: #ffffff;
  }

  .mat-row:nth-child(odd) {
    background-color: #eee8ff;
    mat-cell {
      background-color: #eee8ff;
    }
  }
  .clear {
    clear: both;
  }
}
.bg-limegreen {
  background-color: limegreen;
}
.m-2 {
  margin: 5px 15px !important;
}
.width-70 {
  min-width: 70px;
}
.width-50 {
  width: 49%;
}
.ml-3 {
  margin-left: 10px;
}
.ml-1 {
  margin-left: 5px !important;
}
.page-header-text {
  font-size: 20px !important;
  color: #3f51b5 !important;
}

mat-header-row {
  background: #d6ddff;
}
.form-card {
  background: #f2faff;
  padding: 10px;
  min-height: 485px;
}
.user-profile-img {
  border-radius: 50%;
  box-shadow: 1px 1px 5px 1px #d1cdcd;
}
.projectCreationModal {
  min-height: 570px;
  min-width: 1270px;
  overflow: hidden;

  .classification-types {
    .multiselect-dropdown {
      .dropdown-list {
        .item2 {
          max-height: 175px !important;
        }
      }
    }
  }

  .additional-email-field {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      min-height: 67px;
    }
  }
  .mat-tab-body-wrapper {
    padding: 15px;
    background: #fff8d61f;
    height: 450px;
    overflow: hidden;
    // min-height: 350px;
  }
  .color-code-feild {
    min-height: 45px;
  }
  .btn-primary:disabled {
    background: #e1e1ff !important;
    background-color: #e1e1ff !important;
    color: #fff !important;
    cursor: not-allowed;
  }
  .sub-group-chips {
    .mat-chip-list-wrapper {
      min-height: 25px;
    }
  }
  .mat-tab-body-content {
    overflow: hidden;
    .color-code-feild {
      min-height: 45px;
    }
    .btn-primary:disabled {
      background-color: #e1e1ff !important;
      color: #fff !important;
      cursor: not-allowed;
    }
    .mat-chip-list-wrapper {
      max-height: 45px;
      overflow-y: auto;
      min-height: 38px;
    }
    .email-h-scroll {
      .mat-chip-list-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        min-height: 45px;
        display: flex;
        flex-wrap: nowrap;
      }
    }

    .sub-group-chips {
      .mat-chip-list-wrapper {
        min-height: 25px;
      }
    }
    .additional-details-tab {
      .mat-chip-list-wrapper {
        max-height: 80px;
        overflow-y: auto;
      }
    }
  }
  .mat-dialog-content {
    overflow: hidden;
    min-height: 490px;

    .commercial-table {
      .table {
        tr {
          th {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 50px;
            overflow: hidden;
          }
        }
        tr {
          th:hover {
            white-space: nowrap;
            max-width: 100px;
            overflow: visible;
          }
        }
      }
    }
    .project-modal-table {
      max-height: 155px;
      overflow-y: auto;
    }
    .supportingHours-table,
    .ticket-type-table,
    .rules-table,
    .Attachment-table {
      max-height: 290px;
    }
  }
}
.move-hours-con {
  .mat-dialog-content {
    max-height: 80vh;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .move-hrs-tbl {
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.5em 0 0.5em 0;
    }
    .half-width {
      .mat-form-field-wrapper {
        width: 50%;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
.status-modal {
  .mat-dialog-content {
    overflow: hidden;
    max-height: 465px;
    .mat-tab-body-content {
      overflow: hidden;
      min-height: 300px;
      .s-height-table {
        overflow-x: none !important;
        overflow-y: auto !important;
        max-height: 430px !important;
      }
      .priority-table {
        tbody {
          tr {
            td {
              mat-form-field.example-full-width {
                width: 80px;
                .mat-form-field-wrapper {
                  padding-bottom: 0;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-hint {
  font-size: 10px;
}
.ticketCreationModal {
  .modalHeader {
    min-height: 45px;
    .modal-about {
      min-width: 200px;
    }
    .create-t-btn {
      margin-top: 5px;
      margin-right: 15px;
    }
  }
  .mat-dialog-content {
    max-height: 500px;
    overflow: hidden;
  }
  .createTicketCon {
    .ckeditor-con {
      .ck-editor {
        margin: 3px 0;
      }
      .ck.ck-editor__main > .ck-editor__editable {
        height: 182px;
        overflow-y: auto;
      }
    }
    .mat-form-field-wrapper {
      padding-bottom: 2px;
    }
    .mat-chip-list-wrapper {
      min-height: 79px;
      max-height: 79px;
      overflow-y: auto;
      display: block;
    }
    .file-drop-area {
      min-height: 103px;
      max-height: 103px;
      overflow-y: auto;
      padding: 10px;
    }
  }
}
.ticket-type-modal {
  min-height: 300px;
  overflow: hidden;
  .mat-tab-body-content {
    min-height: 280px;
    overflow: hidden;
  }
}

.mar-top-4 {
  margin-top: 4px !important;
}
.hgt40 {
  height: 40px;
}
.toggle-con {
  .mat-slide-toggle {
    height: 40px !important;
  }
}
.cdk-overlay-pane {
  max-width: 95% !important;
  // min-width: 95vw !important;
}
.mat-tab-label-active,
.mat-tab-label:hover {
  // background: #f1b54a;
  // font-weight: bold;
  color: #000;
}
.ticket-icon {
  line-height: 36px !important;
  padding-right: 7px;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-2 {
  margin-top: 2px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-b-2 {
  margin-bottom: 2px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}

.ticket-list-con {
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    font-size: 11px;
    padding: 5px;
    min-height: 25px;
  }
  .ticket-table {
    height: 295px;
  }
  .mat-table {
    thead {
      background: #d6ddff;
    }
  }
  .info-box7 {
    margin: 5px 0;
    .info-box7-block {
      padding: 5px;
      h4 {
        font-size: 12px;
        line-height: 25px;
        margin-bottom: 0;
        i {
          font-size: 23px;
        }
      }
    }
  }
  .ngx-datatable.material .datatable-header {
    background: #d6ddff;
  }
  .ngx-datatable.fixed-header
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    background: #d6ddff;
    font-weight: bold;
  }
}
.hgt-400 {
  min-height: 400px;
}
.tile-card-con {
  min-height: 550px;
  padding: 10px;
  .info-box7 {
    margin: 5px 0;
    .info-box7-block {
      padding: 10px;
      h4 {
        font-size: 1em;
        line-height: 25px;
        margin-bottom: 0;
      }
    }
  }
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding: 0.5rem;
}

.table-responsive {
  .table {
    thead {
      tr {
        th {
          font-weight: bold;
        }
      }
    }
  }
}

.header-bg-blue {
  thead {
    tr {
      th {
        background-color: #cfd6fb;
      }
    }
  }
}

.table-text-left {
  .table {
    thead {
      tr {
        th {
          text-align: left !important;
        }
      }
    }
    tbody {
      tr {
        th {
          text-align: left !important;
        }
        td {
          text-align: left !important;
        }
      }
    }
  }
}

.s-height-table,
.table-responsive {
  .table {
    thead {
      tr {
        th {
          padding: 5px !important;
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        th {
          padding: 5px !important;
          text-align: center;
        }
        td {
          padding: 5px !important;
          text-align: center;
        }
      }
    }
  }
}

.table-header-fixed {
  .table {
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          z-index: 998;
        }
      }
    }
  }
}
.contact-grid {
  border: 1px solid #dfdfdf;
  margin-bottom: 5px;
  .user-name {
    font-size: 15px;
  }
  .name-center {
    font-size: 14px;
  }
  .email {
    .material-icons {
      font-size: 18px;
      line-height: 20px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}
.mat-table-header-fixed {
  // min-height: 345px;
  max-height: 345px;
  overflow-y: auto;
  .mat-table.cdk-table {
    overflow-y: initial;
  }
  mat-header-row {
    position: sticky;
    top: 0;
    z-index: 998;
  }
}
.mat-table-header-fixed-comon {
  min-height: 429px;
  max-height: 430px;
}

mat-row,
mat-header-row,
mat-footer-row {
  min-height: 40px !important;
}

.mat-tab-label-active,
.mat-tab-label:hover {
  color: #000;
  background: #fff;
}

.small-s-modal {
  .mat-dialog-content {
    // min-height: 150px;
    overflow: hidden;
  }
}

.mat-tab-label .mat-tab-label-content {
  font-weight: bold;
}
.content12 {
  .container-fluid {
    padding: 0;
    overflow: hidden;
    .card {
      margin-bottom: 0;
    }
  }
}

.font-weight-bold {
  font-weight: bold;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(132 138 193);
}

.header-c-name {
  font-weight: 500;
}
.navbar-nav .user_profile .dropdown-toggle img {
  // border-radius: 50%;
  border: 1px solid #d1d8ff;
}
.header-buttons-left {
  .icon-button-demo {
    margin-right: 15px;
  }
}
.align-left {
  text-align: left !important;
}
.table-responsive.s-height-table .table-inside-table {
  table {
    tbody {
      tr {
        td {
          //   background: none !important;
          border: none !important;
        }
      }
    }
  }
}

.addContainer .modalHeader .modal-about {
  margin: 0px;
}
.mat-table.cdk-table {
  max-height: 400px;
  overflow-y: auto;
  // min-height: 350px;
}
.icon-bubbles {
  font-size: 20px;
}
.badge.col-purple {
  margin: 5px;
}
.action-btn {
  padding: 0;
  i {
    font-size: 18px;
  }
}
.edit-ticket-con {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.multiselect-dropdown .dropdown-btn {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  min-height: 45px;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: 210px !important;
  margin-bottom: 3px;
}

// .comments-log
// {
//   .mat-form-field-wrapper
//   {
//     max-height: 70px;
//     overflow-y: scroll;
//   }
// }

.color-code-feild1 {
  min-height: 40px;
}

.scroll-con {
  max-height: 495px;
  overflow-y: auto;
  padding: 0 15px;
}
.hours-table {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    width: 60px;
  }
}
.label {
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 11px;
  margin-right: 2px;
}
h5.scroll-con-headers {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}
span.badge {
  font-size: 9px;
}
.comments-work-log {
  .mat-tab-body-content {
    overflow: hidden !important;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 100px;
    overflow-y: auto;
    max-height: 100px;
  }
  .chat-history-con {
    max-height: 280px;
    overflow-y: auto;
  }
  .view-chat-history-con {
    min-height: 435px !important;
    max-height: 440px !important;
  }
  .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff;
    min-height: 280px;
    // max-height: 400px;
    // overflow-y: auto;
    .avatar img {
      width: 30px;
      height: 30px;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        .avatar img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .message-data {
      margin-bottom: 7px;
      .message-data-name {
        font-size: 13px;
        font-weight: 700;
      }
    }
    .message-data-time {
      color: #434651;
      padding-left: 6px;
    }
    .message {
      color: #444;
      padding: 5px 10px;
      line-height: 15px;
      font-size: 13px;
      @include border-radius(7px);
      margin-bottom: 0;
      width: 90%;
      position: relative;
      word-break: break-word;
      &:after {
        bottom: 100%;
        left: 7%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    .my-message {
      background: #e8e8e8;
      &:after {
        bottom: 100%;
        left: 7%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #e8e8e8;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    .other-message {
      background: #d9e7ea;
      &:after {
        border-bottom-color: #d9e7ea;
        left: 93%;
      }
    }
  }
  .chat-history.view {
    min-height: 420px;
    max-height: 420px;
  }
}

.priority-form {
  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
  }
}

.hours-badge {
  .badge {
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
  }
}
.order-rtm-con {
  .board {
    max-height: 350px;
    overflow-y: auto;
  }
}

.bg-c-yellow-light {
  background: linear-gradient(45deg, #fbef64, #ffcb80);
}
.ticket-list-card {
  .materialTableHeader {
    padding: 5px;
    height: 58px;
  }
  .mat-table-header-fixed {
    min-height: 350px;
    max-height: 360px;
  }
}

.ticket-list-card .body .col-xs-6,
.card .body .col-sm-6,
.card .body .col-md-6,
.card .body .col-lg-6 {
  margin-bottom: 2px;
}
.ticket-top-cards {
  .active .info-box7.order-info-box7,
  .info-box7.order-info-box7:hover {
    border: 2px solid #01228f;
    box-shadow: 1px 1px 10px 3px #01228f;
  }
  .row {
    .col-lg-2 {
      width: 20%;
    }
  }
}
.addContainer {
  .mat-dialog-title {
    position: relative;
    padding-left: 10px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 98%;
    font-weight: bold;
  }
  .mat-dialog-title:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    border-radius: 2px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 50%),
      0 1px 1px rgb(255 255 255 / 30%);
    background: #6777ef;
    background-image: linear-gradient(to top, #6777ef 0, #6777ef 100%);
    background-image: linear-gradient(
      to top,
      #3f51b5,
      #3f51b5,
      #3f51b5,
      #3f51b5,
      #6777ef
    );
  }
}
.mat-dialog-container {
  padding: 10px;
}

.mat-form-card-con {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}

.table-responsive {
  position: relative;
  .tbl-spinner {
    position: fixed;
    left: calc(50% - 50px);
    top: 50%;
  }
}

.btn-primary:disabled {
  background-color: #e1e1ff !important;
  color: #fff !important;
  cursor: not-allowed;
}
.mat-form-field-disabled,
.mat-input-element:disabled {
  cursor: not-allowed !important;
  font-weight: bold !important;
  //color: #6a6a6a !important;
  // background: #ededed !important;
}
.mat-select-disabled .mat-select-value {
  color: #6a6a6a;
}
.mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-outline,
.mat-input-element:disabled {
  color: #6a6a6a;
  background: #ededed !important;
}

// .mat-form-field-empty.mat-form-field-label{
//   font-weight: bold !important;
//   color: #6a6a6a !important;
// }
.version-details-tbl {
  max-height: 385px !important;
  min-height: 385px !important;
  // overflow-y: auto;
}
.max-height-tbl-sm {
  max-height: 250px;
  min-height: 250px;
  overflow-y: auto;
}

.tbdoy-scroll-con {
  .tbdoy-scroll-sm {
    display: block;
    max-height: 250px;
    min-height: 250px;
    overflow-y: auto;
    height: 250px;
    ul {
      .avatar img {
        width: 30px;
        height: 30px;
      }
    }
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  // thead {
  //     width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
  // }
}
.move-hrs-tbl {
  max-height: 310px;
  min-height: 300px;
  overflow-y: auto;
}
.width-100 {
  width: 100px;
}
.height-30 {
  height: 30px;
}
.modal-center-loader {
  min-height: 400px;
}
.line-height-55 {
  line-height: 55px;
}
.min-width-75 {
  min-width: 75px;
}
.min-width-100 {
  min-width: 100px;
}
.min-width-120 {
  min-width: 120px;
}
.min-width-150 {
  min-width: 150px !important;
}
.min-width-200 {
  min-width: 200px !important;
}
.min-width-250 {
  min-width: 250px;
}
.min-width-300 {
  min-width: 300px;
}
.max-width-95 {
  max-width: 95px;
}
.max-width-80 {
  max-width: 80px;
}
.max-width-50 {
  max-width: 50px;
}
.max-width-25 {
  max-width: 25px;
}
.max-width-60 {
  max-width: 60px;
}
.input-width-85 {
  width: 85%;
}
.yellow-tag {
  color: #f2c010;
  font-size: 12px;
  padding-left: 5px;
}
.mat-ticket-list-tbl {
  .mat-sort-header-content {
    font-size: 12px;
  }
  .mat-header-cell {
    font-size: 12px;
  }
  .mat-cell {
    border-right: 1px solid #dfdfdf;
    margin: 0 2px;
    font-size: 11px;
    font-weight: 600;
  }
  .table-img {
    margin: 0 auto;
    .avatar img {
      width: 30px;
      height: 30px;
    }
  }
  .mat-cell:last-child {
    border-right: none;
  }
  mat-cell:first-of-type {
    padding-left: 5px;
  }
}
.font-size-9 {
  font-size: 9px;
}
.font-size-11 {
  font-size: 11px;
  font-weight: bold;
}
.fnt-size-18 {
  font-size: 18px;
}
.fnt-size-25 {
  font-size: 25px;
}
.card-profile-pic {
  width: 90px !important;
  height: 90px !important;
}
.assign-members-con {
  overflow-x: hidden !important;
  max-height: 485px !important;
}
.mat-tooltip {
  font-size: 14px !important;
  max-width: 700px !important;
}
tbody {
  tr {
    td.text-with-tooltip {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.comment-log {
  .mat-tab-header {
    display: none;
  }
}
.Attachment-table {
  table {
    tbody {
      tr {
        td.file-type {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100px;
          min-width: 100px;
          max-width: 100px;
        }
      }
    }
  }
}

.edit-cus-grp-frm {
  .mat-dialog-content {
    max-height: 75vh;
  }
}

.mat-dialog-actions {
  margin: 0 auto;
  width: 50%;
  color: white;
}
.input-with-filter {
  border-bottom: 1px solid grey;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  font-size: inherit;
  padding: 10px;
  position: -webkit-sticky;
}
.comment-tabs {
  .mat-tab-label {
    height: 25px;
  }
}

/* For Desktop Lower screens */
@media (min-width: 1024px) and (max-width: 1199px) {
  .ticket-list-con .info-box7 .info-box7-block h4 {
    font-size: 9px;
  }

  .ticket-list-con .info-box7 .info-box7-block h4 i {
    font-size: 16px;
    line-height: 22px;
  }
  .mat-table-header-fixed-comon {
    min-height: 405px;
    max-height: 410px;
  }
  .mat-ticket-list-tbl {
    .mat-sort-header-content {
      font-size: 12px;
    }
    .mat-cell {
      font-size: 11px;
    }
  }
}
/* For Desktop XS Higher screens */
@media (min-width: 1200px) {
  .ticket-list-card .mat-table-header-fixed {
    min-height: 410px;
    max-height: 411px;
  }
  .timesheet-page-con .mat-table-header-fixed-comon {
    min-height: 330px !important;
    max-height: 335px !important;
  }
}

/* For Desktop SM Higher(1280) screens */
@media (min-width: 1280px) {
  .ticket-list-card .mat-table-header-fixed {
    min-height: 430px;
    max-height: 435px;
  }
  .timesheet-page-con .mat-table-header-fixed-comon {
    min-height: 370px !important;
    max-height: 371px !important;
  }
}

/* For Desktop M Higher(1366) screens */
@media (min-width: 1366px) {
  .ticket-list-card .mat-table-header-fixed {
    min-height: 440px;
    max-height: 445px;
  }

  .timesheet-page-con .mat-table-header-fixed-comon {
    min-height: 380px !important;
    max-height: 381px !important;
  }
}

/* For Desktop L Higher(1440) screens */
@media (min-width: 1440px) {
  .ticket-list-card .mat-table-header-fixed {
    min-height: 480px;
    max-height: 485px;
  }
}

/* For Desktop xl Higher(1680) screens */
@media (min-width: 1680px) {
  .ticket-list-card .mat-table-header-fixed {
    min-height: 500px;
    max-height: 505px;
  }
}

/* For Desktop xxl Higher(1920) screens */
@media (min-width: 1920px) {
  .ticket-list-card .mat-table-header-fixed {
    min-height: 520px;
    max-height: 525px;
  }
}

.input-with-filter {
  top: 0;
  position: sticky;
  z-index: 998;
  background: #fff !important;
}

// section.content.timesheet-page-con
// {
//    margin-top:130px !important;
// }

.timesheet-page-con {
  .mat-select-trigger {
    height: 1.5em;
  }
  .materialTableHeader {
    background: none;
    padding: 10px 0;
  }
  .min-height-110 {
    min-height: 120px;
  }
  .multiselect-dropdown {
    .dropdown-btn {
      min-height: 30px !important;
      border-color: #b0b0b0 !important;
      max-height: 60px !important;
      overflow-y: auto;
    }
  }
  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin-top: 0 !important;
    }
    .mat-form-field-subscript-wrapper {
      padding-top: 8px !important;
    }
  }
  .mat-table {
    min-height: 40vh;
    display: initial;
    .mat-header-row {
      min-height: 35px !important;
      mat-header-cell:first-of-type {
        padding-left: 10px;
      }
      .mat-header-cell {
        min-width: 8%;
        width: 8%;
        max-width: 8%;
        background: #d6ddff !important;
        .mat-sort-header-content {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100px;
          display: block;
        }
      }
    }
    .align-left {
      .mat-sort-header-content {
        text-align: left;
      }
    }
    .mat-row {
      min-height: 20px !important;
      .mat-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 8%;
        width: 8%;
        max-width: 8%;
        display: block;
        padding-left: 3px;
        border-right: 1px solid #ccc;
        .btn-noborder {
          border: none !important;
          background: none !important;
        }
      }
      .mat-cell.table-img {
        text-align: center;
      }
      mat-cell:first-of-type {
        padding-left: 10px;
      }
      mat-cell:last-child {
        border: none;
      }
    }
  }
  .summary-tbl {
    .mat-table {
      .mat-header-row {
        .mat-header-cell {
          min-width: 11%;
          width: 11%;
          max-width: 11%;
        }
      }
      .mat-row {
        .mat-cell {
          min-width: 11%;
          width: 11%;
          max-width: 11%;
        }
      }
    }
  }
  .mat-tab-body.mat-tab-body-active {
    height: 380px;
  }
  .mat-tab-body-content {
    overflow: hidden;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em;
    font-size: 13px;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0.5em;
  }

  .mat-form-field-label-wrapper {
    top: -1.2em; /* Adjust accordingly to keep <mat-label> text vertically centered. */
  }
  .mat-table-header-fixed-comon {
    min-height: 400px;
    max-height: 401px;
    overflow-x: scroll;
    .report-table {
      thead {
        position: sticky;
        top: 0;
        z-index: 998;
        tr {
          th {
            background: #d6ddff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
        }
        tr {
          td {
            .avatar img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.aln_right {
  text-align: left !important;
}
.text-with-dots {
  text-overflow: ellipsis;
  overflow: hidden;
}
.mat-paginator {
  position: sticky;
  bottom: 0;
}
.time-booking-list {
  .mat-table-header-fixed-comon {
    min-height: 70vh;
    max-height: 71vh;
    .mat-table.cdk-table {
      max-height: max-content;
      min-height: 58vh;
    }
  }
}

.modal-close-btn {
  min-width: 40px !important;
  padding: 0 5px !important;
}
.project-wise-report {
  .materialTableHeader {
    background: none;
    padding: 10px 0;
  }
  .mat-table.cdk-table {
    max-height: none;
    .mat-row {
      min-height: 25px !important;
    }
  }
}

.table-card {
  min-height: 205px;
  .table > thead {
    height: 100px;
  }
  .percent-tbl tr td {
    font-size: large;
  }
}
.all-dashboard {
  .mat-tab-body-content {
    padding: 10px;
    min-height: 500px;
    max-height: 510px;
    overflow-y: auto;
    background: #fff;
  }
}
.timesheet-calender-con {
  .mat-dialog-content {
    max-height: 83vh;
    overflow: hidden;
    .container-fluid {
      padding: 0;
      .card-body {
        padding: 0;
        .fc-view {
          padding: 5px 0;
        }
        .fc .fc-toolbar.fc-header-toolbar {
          /* display:none; */
          margin-bottom: 0;
          .fc-toolbar-title {
            font-size: 1.25em;
          }
        }
        .fc .fc-col-header-cell-cushion {
          font-weight: bold;
        }
        .fc .fc-scroller-liquid-absolute {
          height: 55% !important;
        }
      }
    }
  }
}
.HR-timesheet-report {
  table {
    width: 100% !important;
  }
  .mat-select-trigger {
    height: 2.125em;
  }
  mat-header-cell,
  mat-cell {
    justify-content: center !important;
  }
  .mat-form-field-wrapper {
    padding-bottom: 2px;
  }
  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin-top: 0 !important;
    }
    .mat-form-field-subscript-wrapper {
      padding-top: 8px !important;
    }
  }
  .btn-noborder {
    border: none !important;
  }
  .multiselect-dropdown .dropdown-btn {
    padding: 14px 12px !important;
    min-height: 50px;
  }
}

.pl-5 {
  padding-left: 5px;
}
.my-custom-tooltip {
  max-width: unset !important;
}
.paginator-con {
  .mat-select-trigger {
    height: 2.125em;
  }
}
.timehistory-con {
  .mat-select {
    height: 30px;
  }
}
/* New Grid CSS */
.dx-datagrid {
  .more-action-btn {
    height: 20px;
    line-height: 20px;
    .material-icons {
      line-height: 16px;
      height: 15px;
      font-size: 15px;
    }
  }
  .dx-datagrid-headers {
    .dx-row > td {
      padding: 8px 4px !important;
    }
  }
  .dx-row > td {
    padding: 3px !important;
  }
  .table-img img {
    width: 20px;
    height: 20px;
    padding: 0px !important;
  }
  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: 30px;
  }
  .dx-pager .dx-page-sizes .dx-selection,
  .dx-pager .dx-pages .dx-selection {
    background-color: #3452b4;
  }
}

.btn-blue {
  background-color: #3452b4;
  .mat-icon {
    color: white;
  }
}
.pos-rel {
  position: relative !important;
}
.refresh-btn {
  position: absolute !important;
  right: 50px;
}
.span-text {
  font-size: 12px;
  color: #444;
}
.text-right {
  text-align: right;
}
.sub-header {
  padding: 3px 10px;
  border: 1px solid #ccc;
}
.text-blue {
  color: blue;
}
.text-green {
  color: green;
}

.dxc-arg-elements {
  text {
    font-size: 14px !important;
    font-weight: bold !important;
  }
}
.dashboard-task-infos1 {
  min-height: 200px;
}

.dx-data-tbl-con {
  max-height: 480px;
  min-height: 479px;
  overflow: hidden;
  .dx-datagrid-rowsview {
    overflow: auto;
    height: 280px;
  }
}

.summary-wise-chart {
  .dxc-arg-elements {
    text {
      font-size: 11px !important;
      font-weight: bold !important;
    }
  }
}
.bg-black {
  .dxc-title,
  .dxc-item,
  .dxc-arg-elements {
    fill: white !important;
    text {
      fill: white !important;
    }
  }
}

.banner-card {
  margin: 5px 0 5px 0;
  .card-block {
    padding: 5px 0;
  }
}

.ticket-management-grid .dx-datagrid-header-panel {
  height: 52px;
}
.ticket-management-grid .dx-datagrid-headers {
  height: 68px;
}

.ticket-management-grid .mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 18px;
  height: 20px;
}

.time-hours-view-popup {
  width: 800px;
  height: 400px;
}
.tbl-badge {
  color: black;
  width: 150px;
  border: none !important;
  font-size: 13px !important;
}
.bg-light-green {
  background: lightgreen !important;
}
.bg-light-orange {
  background: lightsalmon !important;
}

.tbl-green-event {
  background: lightgreen !important;
  color: black !important;
  width: 139px;
  border: none !important;
  font-size: 13px !important;
}
.tbl-lightsalmon-event {
  background: lightsalmon !important;
  color: black !important;
  width: 139px;
  border: none !important;
  font-size: 13px !important;
}
.tbl-lightYellow-event {
  background: #feff7b !important;
  color: black !important;
  width: 139px;
  border: none !important;
  font-size: 13px !important;
}
.time-hours-view-popup {
  .table.header-bg-blue tr {
    th {
      text-align: center;
    }
  }
  tr {
    td {
      text-align: center;
    }
  }
}

.content {
  .tabbable-line {
    .card {
      height: 90vh;
      .body {
        height: 100%;
        .min-height-table {
          min-height: auto;
          max-height: max-content;
          overflow: auto;
          .dx-datagrid-rowsview {
            min-height: 55vh;
            overflow-y: auto;
            height: 55vh;
          }
        }
      }
    }
  }
}

.ticket-management-grid {
  table {
    tbody {
      tr {
        height: 20px !important;
        max-height: 20px !important;
        td {
          padding: 0 !important;
          font-size: 12px !important;
          height: 18px !important;
          max-height: 18px !important;
          line-height: 12px !important;
          .dx-template-wrapper {
            .mat-cell {
              font-size: 12px !important;
              td {
                ul {
                  li {
                    span.badge {
                      padding: 3px !important;
                      font-size: 9px !important;
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dx-pager {
    padding: 2px 5px;
    .dx-page-sizes .dx-page-size {
      padding: 5px 7px;
      line-height: 12px;
      font-size: 12px;
    }
  }
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left !important;
}
/* Dev-express Table header bg-color */
.dx-datagrid {
  .dx-datagrid-headers, .dx-cell-focus-disabled, .dx-datagrid-drag-action  {
    background-color: #d6ddff;
  }
}
 
// ::-webkit-scrollbar {
//   width: 0px;
// }
.timesheet-page-con-new .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable{
  height: 87vh !important;
  overflow-y: auto !important;
}